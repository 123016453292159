/* You can add global styles to this file, and also import other style files */

@media print {
  .print {
    display: initial !important;
  }

  .no-print {
    display: none !important;
  }

  .logo-saas-bg {
    vertical-align: middle;
    margin: 10px 0 0 25px;
    height: 35px;
    /* border: 1px solid red; */
    /* width: 20px; */
  }

  .underline {
    border-bottom: 1px solid rgb(201, 201, 201) !important;
  }
}

html,
body {
  height: 100vh;
  margin: 0;
  font-family: Nunito, sans-serif !important;
}

.content {
  margin: 1.5rem;
}

.title {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.filter {
  border: solid 1px #dadada;
  box-shadow: 0px 2px 10px lightgrey;
  border-radius: 0.5rem;
  width: fit-content;
  padding: 5px;
}

.print {
  display: none;
}

.line-sep .mat-form-field-appearance-fill .mat-form-field-flex {
  /* border-left: 1px solid #dadada; */
  border-radius: 0px 4px 0 0 !important;
}

.dateInput .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 10px !important;
}

.selector .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 10px !important;
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: transparent !important;
}

.mat-form-field-ripple {
  background-color: transparent !important;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mdc-evolution-chip__text-label {
  color: #780c9c !important;
}

.mat-calendar-body-selected {
  background-color: rgb(120, 12, 156) !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgb(120, 12, 156) !important;
}

.mat-option.mat-active {
  color: rgb(120, 12, 156) !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #780c9c !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  border: solid 1px #780c9c !important;
}

.mat-select-arrow {
  color: #780c9c !important;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.myPanelClass {
  margin-top: 5px !important;
}

.mat-form-field-infix {
  width: auto !important;
}

.mat-width {
  width: 25%;
  min-width: 8rem;
}

.mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-input-element .mat-table .mat-select .mat-option {
  font-family: Nunito, sans-serif !important;
}

.mat-header-cell {
  font-family: Nunito, sans-serif !important;
  font-size: 1rem;
  font-weight: bold;
}

.mat-cell {
  font-size: 1rem;
}

.mat-form-field {
  font-family: Nunito, sans-serif !important;
}

/* .mat-mdc-menu-item {
  font-family: Nunito, sans-serif !important;
  font-size: 1rem;
  color: #780c9c;
} */

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #01dc00;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: white;
  border: solid 1px #01dc00;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-ripple .mat-ripple-element {
  background-color: rgba(29, 135, 229, 0.2);
}

.mat-drawer-container {
  background-color: white;
  overflow: hidden;
  height: 100%;
}

.mat-drawer-content {
  height: 100%;
  overflow: hidden;
}

.mat-drawer-side {
  border: none !important;
  background-color: #f9f0fd !important;
}

.mat-drawer-inner-container {
  width: 190px !important;
  min-width: 190px !important;
  max-width: 190px !important;
}

.mat-mdc-header-cell {
  font-family: Nunito, sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.2rem;
}

.mat-mdc-row,
.mdc-data-table__content {
  font-family: Nunito, sans-serif !important;
  font-size: 1rem !important;
}

.mat-drawer-inner-container h3 {
  padding-left: 23px;
  margin: 4rem 0 0;
  color: #780c9c;
  font-size: 1rem;
}

app-header {
  z-index: 9;
}
mat-sidenav-container {
  z-index: 8;
}

mat-list-item .mat-list-item-content {
  padding: 0 0 0 23px !important;
}

mat-list-item .mat-list-item-content span {
  padding-left: 0.8rem;
  font-weight: 600;
  font-size: 1rem;
}

@font-face {
  font-family: "BradleyHand";
  src: url("./assets/fonts/BradleyHand.TTF");
}

.mdc-line-ripple::before,
.mdc-line-ripple::after {
  border-bottom-style: none !important;
}

.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled)
  .mat-mdc-text-field-wrapper,
.mdc-text-field--filled.mdc-text-field--disabled,
.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-form-field-focus-overlay {
  border-radius: 0.5rem;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: #f8e4ff !important;
}

.mat-button-toggle-group-appearance-standard {
  border-radius: 1rem !important;
}

.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: #f8e4ff !important;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  color: #780c9c !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #780c9c !important;
  border-color: #db72ff !important;
  color: #ffffff;
}

.mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: #e8a9ff;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.mat-datepicker-content .mat-calendar {
  width: 330px !important;
  height: auto !important;
}

mat-paginator {
  background-color: transparent !important;
}

.mat-button-toggle-label-content {
  line-height: 2.5rem !important;
}
.searchPanel {
  /* buscar y eliminar de los .scss */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}

.buttons {
  display: flex;
  margin: 2rem 0 2rem 0;
  gap: 0.5rem;
}
