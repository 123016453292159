@use "@angular/material" as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

body {
  font-family: Nunito !important;
}


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gobakery-pwa-primary: mat.define-palette(mat.$purple-palette);
$gobakery-pwa-accent: mat.define-palette(mat.$purple-palette, 200, 100, 300);
$gobakery-pwa-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gobakery-pwa-theme: mat.define-light-theme(
  (
    color: (
      primary: $gobakery-pwa-primary,
      accent: $gobakery-pwa-accent,
      warn: $gobakery-pwa-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gobakery-pwa-theme);
